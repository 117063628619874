:root {
  /* do not modify line bellow */
  /* palette colors start */
  --white: #ffffff;
  --black: #000000;

  --white-a10: #ffffff19;
  --white-a20: #ffffff33;
  --white-a30: #ffffff4c;

  --bg-blue: #0b1427;
  --purplish-blue: #0e1a32;
  --gentian-blue: #142d53;
  --lavender: #cee4fa;
  --lavender-a16: #cee4fa29;
  --maya-blue: #7dbeff;
  --light-azure: #a4cdfa;
  --bleu-de-france: #2f92f6;

  --bleu-de-france-a10: #2f92f619;
  --bleu-de-france-10: #10203b;
  --bleu-de-france-a20: #2f92f633;
  --bleu-de-france-a30: #2f92f64d;

  --cerulean-blue: #2c529c;
  --cerulean-blue-a90: #2c529ce5;
  --ocean-blue: #183368;
  --brilliant-yellow: #fdc33c;

  --yellow-ivory: #f2c94c;
  --yellow-ivory-a10: #f2c94c19;
  --yellow-ivory-a20: #f2c94c33;

  --orange-dawn: #eb5757;
  --orange-dawn-a10: #eb575719;
  --orange-dawn-a20: #eb575733;
  --orange-dawn-20: #392231;

  --grey-umber: #333333;
  --green-confirm: #1cd26a;
  --bear-ear: #886219;

  --sea-green: #219653;
  --sea-green-a10: #21965319;

  --dim-grey: #666666;
  --ash-gray: #bdbdbd;
  --platinum-gray: #e0e0e0;
  --ivory-mist: #f2f2f2;
  --navy-blue: #18258f;
  --medium-gray: #828282;
  --mustard-yellow: #face5e;
  --light-blue: #ebf5fe;
  --sky-blue: #2f80ed;
  --orange: #f2994a;
  --slate-gray: #858b96;
  --midnight-blue: #0c172e;
  --navy: #192949;
  --dark-navy: #1a2948;
  --gray: #9d9d9d;
  --light-gray: #919191;
  /* palette colors end */
  /* do not modify line above */

  /* size units start */
  /* todo: define size units here */
  /* size units end */

  /* borderRadius start */
  /* todo: add more */
  --border-radius-round: 9999px;
  /* borderRadius start */

  /* ––– */

  /* TODO: add semantic color tokens here: */
  /* base semantic tokens start */
  --color-foreground: var(--white);
  --color-foreground-alpha-10: var(--white-a10);
  --color-foreground-alpha-20: var(--white-a20);
  --color-foreground-alpha-30: var(--white-a30);

  --color-foreground-contrast: var(--black);
  --color-foreground-contrast-alpha-10: var(--black-a10);

  --color-foreground-contrast-2: var(--grey-umber);

  --color-background-contrast: var(--white);

  --color-primary: var(--bleu-de-france);
  --color-primary-alpha-10: var(--bleu-de-france-a10);
  --color-primary-shade-10: var(--bleu-de-france-10);
  --color-primary-alpha-20: var(--bleu-de-france-a20);

  --color-primary-light: var(--lavender);
  --color-primary-light-a16: var(--lavender-a16);
  --color-primary-dimmed: var(--cerulean-blue);
  --color-primary-dimmed-a90: var(--cerulean-blue-a90);
  --color-primary-dark: var(--ocean-blue);

  --color-secondary: var(--yellow-ivory);
  --color-secondary-alpha-10: var(--yellow-ivory-a10);
  --color-secondary-alpha-20: var(--yellow-ivory-a20);

  --color-warning: var(--orange-dawn);
  --color-warning-alpha-10: var(--orange-dawn-a10);
  --color-warning-alpha-20: var(--orange-dawn-a20);
  --color-warning-shade-20: var(--orange-dawn-20);
  --color-success: var(--sea-green);
  --color-success-alpha-10: var(--sea-green-a10);
  /* base semantic tokens end */

  /* TODO: will be #fff after the redesign */
  /* TODO: will be #fff after the redesign */
  --secondary-text-color: var(--white);
  /* TODO: will not change after redesign */
  --light-neutral-text-color: var(--dim-grey);
  /* TODO: will not change after redesign */
  /* TODO: will be #fff after the redesign */
  /* TODO: will not change after redesign */
  --main-title-color: var(--white);
  /* TODO: will be #fff after the redesign */
  --primary-title-color: var(--navy-blue);
  /* TODO: will be #fff after the redesign */
  /* TODO: will be --bleu-de-france-10=rgba(47, 146, 246, 0.10) after the redesign */
  /* TODO: will be #fff after the redesign */
  --line-color: var(--medium-gray);
  --error-text-color: var(--orange-dawn);
  --warning-text-color: var(--orange-dawn);
  --success-text-color: var(--sea-green);
  --confirming-text-color: var(--yellow-ivory);
  --primary-button-bg-color: var(--bleu-de-france);
  --primary-button-color: var(--white);
  --primary-button-hover-color: var(--bear-ear);
  --primary-button-bg-hover-color: var(--yellow-ivory);
  --primary-button-disabled-color: var(--ash-gray);
  --primary-button-disabled-bg-color: var(--ivory-mist);
  --input-shadow: var(--ash-gray);
  --input-label: var(--dim-grey);
  /* TODO: will not change after redesign */
  --light-bg-color: var(--white);
  /* TODO: will not change after redesign */
  --border-color: var(--platinum-gray);
  --tab-title-color: var(--bleu-de-france);
  --header-color: var(--white);
  --header-link-hover-color: var(--yellow-ivory);
  --footer-link-hover-color: var(--mustard-yellow);
  --footer-link-icon-color: var(--bleu-de-france);
  --footer-title-hover-color: var(--light-azure);
  --link-color: var(--bleu-de-france);
  --link-hover-color: var(--navy-blue);
  --up-button-icon-color: var(--bleu-de-france);
  --up-button-bg-color: var(--lavender);
  --list-marker-color: var(--bleu-de-france);
  --dashboard-stat-item-bg-color: var(--gentian-blue);
  --review-item-serviceIcon-bg-color: var(--light-blue);
  --calc-swap-button-bg-color: var(--bleu-de-france);
  --arcticPopup-badge-bg-color: var(--sea-green);
  --arcticPopup-description-color: var(--dim-grey);
  --content-headerItem-active-color: var(--bleu-de-france);
  --content-headerItem-completed-color: var(--yellow-ivory);
  --indicators-color: var(--bleu-de-france);
  --indicators-waiting-color: var(--ash-gray);
  --panelCheckAmount-color: var(--bleu-de-france);
  --panelConfirmTransaction-color: var(--bleu-de-france);
  --processingContent-color: var(--bleu-de-france);
  --currencyOption-title-color: var(--dim-grey);
  --selectCurrencies-icon-color: var(--bleu-de-france);
  --popup-error-content-color: var(--bleu-de-france);
  --how-it-work-bg-color: var(--sky-blue);
  --chart-tooltip-bg-color: var(--white);
  --chart-tooltip-border-color: var(--medium-gray);
  --chart-tooltip-text-color: var(--black);
  --first-section-author-back-hover-color: var(--yellow-ivory);
  --extraFields-error-color: var(--orange-dawn);
  --feedback-pointItem-bg-color: var(--ivory-mist);
  --feedback-pointItem-yellow-bg-color: var(--yellow-ivory);
  --feedback-star-color: var(--yellow-ivory);
  --feedback-input-placeholder-color: var(--ash-gray);
  --walletsBlock-icon-bg-color: var(--orange);

  /* New final colors that will not change during the redesign */
  --main-dark-color: var(--purplish-blue);
  --text-deep-dark-color: var(--black);
  --secondary-dark-color: var(--bleu-de-france);
  --special-dark-color: var(--maya-blue);
  --secondary-bg-dark-color: var(--bg-blue);
  --light-dark-color: var(--slate-gray);
  --delimiter-line-dark-color: var(--cerulean-blue);
  --delimiter-line-secondary-dark-color: var(--purplish-blue);
  --accented-text-dark-color: var(--yellow-ivory);
  --accented-text-dark-bg-color: var(--yellow-ivory-a20);
  --accented-strong-dark-color: var(--brilliant-yellow);
  --header-secondary-text-dark-color: var(--midnight-blue);
  --header-bg-dark-color: var(--purplish-blue);
  --mobileNav-bg-dark-color: var(--navy);
  --light-menu-bg-hover-dark-color: var(--lavender);
  --news-bg-dark-color: var(--midnight-blue);
  --image-bg-dark-color: var(--ocean-blue);
  --widget-block-bg-dark-color: var(--purplish-blue);
  --footer-partners-bg-dark-color: var(--bg-blue);
  --today-statistics-value-dark-color: var(--maya-blue);
  --pagination-numbers-dark-color: var(--ash-gray);
  --pagination-dots-normal-dark-color: var(--cerulean-blue);
  --pagination-dots-active-dark-color: var(--bleu-de-france);
  --pagination-dots-hover-dark-color: var(--yellow-ivory);
  --light-button-bg-dark-color: var(--sky-blue);
  --indicator-line-dark-color: var(--ocean-blue);
  --indicator-progress-bg-dark-color: var(--dark-navy);
  --timer-time-dark-color: var(--mustard-yellow);
  --arcticPopup-heading-dark-color: var(--ocean-blue);
  --arcticPopup-platform-dark-color: var(--cerulean-blue);
  --search-input-bg-dark-color: var(--ocean-blue);
  --currencies-card-bg-dark-color: var(--gentian-blue);
  --currencies-card-ticker-dark-color: var(--lavender);
  --chart-tooltip-dot-dark-color: var(--maya-blue);
  --link-dark-color: var(--maya-blue);
  --modal-bg-dark-color: var(--gentian-blue);
  --loader-primary-color: var(--sky-blue);
  --loader-secondary-color: var(--bear-ear);
  /* todo: fixit */
  --blurred-bg-color: #2c41ffb2;
  --providers-error-color: var(--orange-dawn);
  --section-bg-dark-color: var(--gentian-blue);
  --active-breadcrumb-color: var(--maya-blue);
  --breadcrumb-hover-color: var(--mustard-yellow);

  /* Button colors */
  --primary-button-dark-color: var(--white);
  --primary-button-bg-dark-color: var(--bleu-de-france);
  --primary-button-hover-dark-color: var(--bear-ear);
  --primary-button-bg-hover-dark-color: var(--yellow-ivory);
  --secondary-button-dark-color: var(--bear-ear);
  --secondary-button-hover-dark-color: var(--white);
  --secondary-button-bg-dark-color: var(--yellow-ivory);
  --secondary-button-bg-hover-dark-color: var(--bleu-de-france);
  --stroke-button-dark-color: var(--yellow-ivory);
  --stroke-button-hover-dark-color: var(--bleu-de-france);
  --neutral-button-dark-color: var(--white);
  --neutral-button-bg-dark-color: var(--ocean-blue);
  --neutral-button-bg-hover-dark-color: var(--bleu-de-france);
  --neutral-dark-color: var(--gray);
  --accented-dark-color: var(--grey-umber);
  --calc-notification-bg-dark-color: var(--lavender);
  --default-currency-badge-color: var(--light-gray);

  --bg-image-gradient: linear-gradient(
    335deg,
    rgba(61, 80, 255, 0.6) -0.06%,
    rgba(61, 80, 255, 0) 55.28%
  );
  --bg-error-page-gradient: linear-gradient(
    335deg,
    rgba(61, 80, 255, 0.6) -0.06%,
    rgba(61, 80, 255, 0) 99.28%
  );
  --bg-fade-card-gradient: linear-gradient(
    96deg,
    rgba(20, 45, 83, 0.8) 0%,
    rgba(20, 45, 83, 0) 106.1%
  );
  --bg-up-fade-card-gradient: linear-gradient(180deg, #142d53 0%, rgba(20, 45, 83, 0) 100%);
  --bg-left-fade-card-gradient: linear-gradient(
    114.13deg,
    #142d53 0.9%,
    rgba(20, 45, 83, 0) 108.81%
  );
  --market-data-border-gradient: linear-gradient(
    271.08deg,
    rgba(47, 146, 246, 0) -2.64%,
    #2f92f6 52.46%,
    rgba(47, 146, 246, 0) 101.2%
  );
  --join-now-gradient: linear-gradient(270deg, #162e53 0%, #243c6c 50.29%, #134a9d 102.12%);
  --footer-partners-gradient: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 20%,
    hsl(0 0% 0% / 1) 80%,
    hsl(0 0% 0% / 0)
  );
  --skeleton-gradient: linear-gradient(
    90deg,
    rgba(125, 190, 255, 0.8) 0%,
    rgba(125, 190, 255, 0.16) 100%
  );
  --wallet-card-gradient: linear-gradient(127.98deg, #307ad2 0%, #2d89e7 100%);
  --transition-rapid: ease-in-out 0.15s;
  --transition: ease-in-out 0.3s;
  --list-marker-size: 10px;
  --loader-animation: bubble 1.3s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);
  --skeleton-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

  --weight-normal: 400;
  --weight-medium: 500;
  --weight-bold: 700;

  /* Desktop Fonts */
  --font-h1: 400 55px/65px var(--font-gothamPro);
  --font-h2: 400 45px/55px var(--font-gothamPro);
  --font-h3: 400 40px/50px var(--font-gothamPro);
  --font-h4: 700 40px/50px var(--font-gothamPro);
  --font-h5: 400 30px/40px var(--font-gothamPro);
  --font-h6: 400 24px/34px var(--font-gothamPro);
  --font-h7: 700 18px/26px var(--font-gothamPro);
  --font-h8: 400 18px/26px var(--font-gothamPro);
  --font-h9: 400 16px/24px var(--font-gothamPro);
  --font-h10: 400 16px/24px var(--font-gothamPro);
  --font-h11: 400 14px/18px var(--font-gothamPro);
  --font-h12: 400 14px/24px var(--font-gothamPro);
  --font-h13: 400 12px/16px var(--font-gothamPro);
  --font-h14: 600 20px/30px var(--font-gothamPro);
  --font-blockchain: 500 12px var(--font-gothamPro);
  --font-button: 700 20px/30px var(--font-gothamPro);
  --font-button-small: 400 16px/26px var(--font-gothamPro);
  --font-button-stroke: 400 16px/24px var(--font-gothamPro);
  --font-calculator-namecoin: 500 22px/22px var(--font-gothamPro);
  --font-badge: 500 10px/12px var(--font-gothamPro);
  --font-chart: 400 10px/12px var(--font-gothamPro);
  --font-page-error: 400 150px/150px var(--font-gothamPro);
  --font-large: 500 90px/90px var(--font-gothamPro);

  /* Tablet Fonts */
  --font-h1-md: 400 36px/50px var(--font-gothamPro);
  --font-h2-md: 400 26px/36px var(--font-gothamPro);
  --font-h3-md: 400 22px/36px var(--font-gothamPro);
  --font-h4-md: 400 20px/34px var(--font-gothamPro);
  --font-h5-md: 400 18px/28px var(--font-gothamPro);
  --font-h6-md: 400 18px/24px var(--font-gothamPro);
  --font-h7-md: 400 16px/26px var(--font-gothamPro);
  --font-h8-md: 400 14px/24px var(--font-gothamPro);
  --font-h9-md: 400 16px/26px var(--font-gothamPro);
  --font-h10-md: 400 14px/24px var(--font-gothamPro);
  --font-h11-md: 400 14px/24px var(--font-gothamPro);
  --font-h12-md: 400 14px/19px var(--font-gothamPro);
  --font-h14-md: 600 16px/24px var(--font-gothamPro);
  --font-button-md-bold: 700 16px/24px var(--font-gothamPro);
  --font-headline-4: 700 20px/26px var(--font-gothamPro);

  /* Mobile Fonts */
  --font-h1-sm: 400 24px/30px var(--font-gothamPro);
  --font-h2-sm: 400 22px/30px var(--font-gothamPro);
  --font-h3-sm: 400 20px/28px var(--font-gothamPro);
  --font-h4-sm: 700 20px/24px var(--font-gothamPro);
  --font-h5-sm: 400 18px/28px var(--font-gothamPro);
  --font-h6-sm: 700 18px/26px var(--font-gothamPro);
  --font-h7-sm: 400 16px/24px var(--font-gothamPro);
  --font-h8-sm: 400 14px/24px var(--font-gothamPro);
  --font-h9-sm: 400 14px/24px var(--font-gothamPro);
  --font-h11-sm: 400 16px/24px var(--font-gothamPro);
  --font-h13-sm: 400 14px/20px var(--font-gothamPro);
  --font-h14-sm: 400 14px/18px var(--font-gothamPro);
  --font-button-big-sm-bold: 700 16px/24px var(--font-gothamPro);
  --font-ultra-sm: 400 12px/16px var(--font-gothamPro);
}

@keyframes bubble {
  0% {
    transform: scale(0.2);
    opacity: 0.2;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.2);
    opacity: 0.2;
  }
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
